import React, { useEffect } from "react";
import hoverSound from "/audio/take-me-home.mp3"; // Adjust the path to your audio file

const HoverAudioComponent: React.FC = () => {
    useEffect(() => {
        const audio = new Audio(hoverSound);

        const playAudio = () => {
            audio.play().catch(error => {
                console.error("Audio playback failed:", error);
            });
        };

        const stopAudio = () => {
            audio.pause();
            audio.currentTime = 0;
        };

        const enableAudio = () => {
            document.removeEventListener("click", enableAudio);
            document.removeEventListener("keydown", enableAudio);
            const elements = document.querySelectorAll(".audio-trigger");

            elements.forEach(element => {
                element.addEventListener("mouseenter", playAudio);
                element.addEventListener("mouseleave", stopAudio);
            });
        };

        document.addEventListener("click", enableAudio);
        document.addEventListener("keydown", enableAudio);

        return () => {
            document.removeEventListener("click", enableAudio);
            document.removeEventListener("keydown", enableAudio);
            const elements = document.querySelectorAll(".audio-trigger");

            elements.forEach(element => {
                element.removeEventListener("mouseenter", playAudio);
                element.removeEventListener("mouseleave", stopAudio);
            });
        };
    }, []);

    return null;
};

export default HoverAudioComponent;
